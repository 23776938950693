import { Api } from '@api/ApiTransport';
import STATUS from '@constants/STATUS';
import ReactGA from "react-ga4";
import { create } from 'zustand';
import { DocumentData } from './storeAuth';
import { useStoreError } from './storeError';
import { useStoreLoader } from './storeLoader';
import ROUTES from '@routes/constants';

const api = Api.getInstance()

export type Session = {
    id: number;
    createdAt: string;
    updatedAt: string | null;
    status: number;
    idUser: number;
    price?: number;
    urlSign?: string;
    documents?: DocumentData[];
};

export type Payload = {
    status: number;
    typeOwner?: number;
}

export type CheckSignedReturn = {
    intervalIdSigned: NodeJS.Timeout | null;
    timeoutSigned: NodeJS.Timeout;
};

export type sessionState = {
    session: Session;
    prevSessionStatus: number | null;
    setSession: (session: Session) => void;
    resetSession: () => void;
    getSession: () => Promise<void>;
    getSessionById: (id: string) => Promise<void>;
    updateSession: (payload: any) => Promise<void>;
    uploadSavings: (callback: () => void) => Promise<() => void>;
    deleteAllSessionBills: () => Promise<void>;
    getSignature: (callback: (data: any) => void) => Promise<void>;
    checkSessionCompleted: (callback: () => void) => Promise<() => void>;
    checkSigned: (isTimeout: boolean, flowSorgente: string | undefined) => Promise<() => void>;
    saveIdAndToken: (payload: any, callback?: (id: string) => void) => Promise<void>;
    getSavedIdAndToken: (payload: string, callback?: (id: string) => void) => Promise<void>;
    deleteSavedIdAndToken: (payload: string, callback?: () => void) => Promise<void>;
    paramToken: string;
    paramSessionId: string;
    openSession: (idSession: number) => Promise<string | null>;
    createSession: (idUser: number) => Promise<string | null | undefined>;
    openFriendSession: (idSession: number, idUser: number) => Promise<string | null | undefined>;
    createFriendSession: (idUser: number) => Promise<string | null | undefined>;
    archiveSession: (sessionId: number, callback: () => void) => Promise<void>;
    getMongoSessionInfo: (sessionId: number) => Promise<void>;
    sessionInfoMongo: any;
};

export const useSessionStore = create<sessionState>((set, get) => ({
    session: {} as Session,
    prevSessionStatus: null,
    setSession: (session: Session) => set({ session }),
    resetSession: () => set({ session: {} as Session }),
    getSession: async () => {
        const { data } = await api.get("/sessions/user");
        set({ session: data.sessions[data.sessions.length - 1] });
    },
    getSessionById: async (id) => {
        const { data } = await api.get(`/sessions/getSessionById/${id}`);
        set({ session: data.session });
    },
    updateSession: async (payload: Payload) => {
        try {
            const { session } = get();
            set({ prevSessionStatus: session.status });
            const { data } = await api.post(`/sessions/update/${session?.id}`, {
                status: payload.status,
                typeOwner: payload.typeOwner,
            })
            set({ session: data.session });

            //se sono in modalita onboarding senza registrazione, mi salvo id sessione in localstorage cosi da poterlo recuperare in seguito in fase registrazione reale
            const isOnboardingNoSign = localStorage.getItem('isOnboarding_without_sign');
            if (isOnboardingNoSign) {
                localStorage.setItem('onBoardingNoSignidSession', session.id.toString());
            }

        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    paramToken: '',
    paramSessionId: '',
    saveIdAndToken: async (payload, callback) => {
        const { data } = await api.post(`/saveTokenAndInfo`, payload);
        localStorage.setItem('idToFetchSavedSessionTokenAndId', data.infoToken.id);
        api.setAuthorizationHeader(data.infoToken.token);
        set({ paramToken: data.infoToken.token })
        set({ paramSessionId: data.infoToken.idSession })
        callback?.(data.infoToken.idSession);
    },
    getSavedIdAndToken: async (payload, callback) => {
        const { data } = await api.get(`/getTokenInfo/${payload}`);
        api.setAuthorizationHeader(data.infoToken.token);
        set({ paramToken: data.infoToken.token })
        set({ paramSessionId: data.infoToken.idSession })
        callback?.(data.infoToken.idSession);
    },
    deleteSavedIdAndToken: async (payload, callback) => {
        await api.delete(`/deleteToken/${payload}`);
        localStorage.removeItem('idToFetchSavedSessionTokenAndId');
        api.resetAuthorizationHeader();
        set({ paramToken: "" })
        set({ paramSessionId: "" })
        callback?.();
    },
    uploadSavings: async (callback) => {
        const { session, getSession, getSessionById, paramToken, paramSessionId } = get();

        const loadSavings = async () => {
            try {
                if (session.id) {
                    const { status } = await api.get(`/sessions/loadSavings/${session.id}`)
                    console.log(session, "session inside loadSavings");

                    if (status === 202 && (session.price == null || typeof session.price === 'string')) {
                        return false
                    }
                    return true;
                }
            } catch (error: Error | any) {
                const setError = useStoreError.getState().setError;
                setError(error.response?.data?.message || error.response?.data || error?.message);
                return true;
            }
        }

        const loadSavingsCheck = await loadSavings();

        let timeout: NodeJS.Timeout;
        let intervalId: NodeJS.Timeout;
        if (!loadSavingsCheck) {
            console.log(loadSavingsCheck, "loadSavingsCheck");
            const initialDelay = 20000;
            const intervalDelay = 5000;
            let attemptCount = 0;
            const maxAttempts = 14; // 14 * 5 = 70 seconds

            timeout = setTimeout(async () => {
                console.log("timeout");
                intervalId = setInterval(() => {
                    attemptCount++;
                    console.log("interval");
                    const latestSession = get().session;
                    if (typeof latestSession.price === 'number') {
                        console.log("clear interval");
                        clearInterval(intervalId);
                        callback();
                    } else {
                        console.log("keep going")
                        if (attemptCount >= maxAttempts) {
                            const setError = useStoreError.getState().setError;
                            setError("Over 200 seconds wait");
                        }
                        if (paramSessionId && paramToken) {
                            getSessionById(paramSessionId);
                        } else {
                            getSession();
                        }
                    }
                }, intervalDelay);
            }, initialDelay);
        }

        return () => {
            clearInterval(intervalId);
            clearTimeout(timeout);
        };
    },
    deleteAllSessionBills: async () => {
        const { session } = get();
        console.log(session, "session inside deleteAllSessionBills");
        await api.delete(`/documents/financial/${session.id}`);
    },
    getSignature: async (callback) => {
        const { session } = get();
        const { data } = await api.get(`/sessions/complete/${session?.id}`);
        set({ session: data.session });
        callback?.(data.session);
    },
    checkSessionCompleted: async (callback) => {
        let intervalIdSession: NodeJS.Timeout;
        const { session, updateSession } = get();

        const checkSessionCompletedPolling = async () => {
            try {
                const { status, data } = await api.get(`/sessions/checkCompleted/${session?.id}`);
                set({ session: data.session });
                const latestSession = get().session;

                if (status === 202 && (!latestSession?.urlSign || latestSession?.urlSign === '')) {
                    return false;
                }
                return true;
            } catch (error: Error | any) {
                clearInterval(intervalIdSession);
                const setError = useStoreError.getState().setError;
                setError(error.response?.data?.message || error.response?.data || error?.message);
                // session?.id && updateSession({ status: STATUS.ID_LOAD_SUCCESS });
            }
        };

        let attemptCount = 0;
        const maxAttempts = 100; // 100 * 2 = 200 seconds

        intervalIdSession = setInterval(async () => {
            attemptCount++;
            const sessionCompleted = await checkSessionCompletedPolling();
            if (sessionCompleted) {
                clearInterval(intervalIdSession);
                callback?.();
            }
            if (attemptCount >= maxAttempts) {
                const setError = useStoreError.getState().setError;
                setError("Over 200 seconds wait");
            }
        }, 2000);

        return () => clearInterval(intervalIdSession);
    },
    checkSigned: async (isTimeout = true, flowSorgente) => {
        const { session, updateSession } = get();
        let timeoutSigned: NodeJS.Timeout;
        let intervalIdSigned: NodeJS.Timeout | null = null;

        const checkSignedPolling = async () => {
            try {
                const { status, data } = await api.get(`/sessions/checkSigned/${session?.id}`);
                if (status === 202) {
                    return false;
                }

                if (status === 200 && data.session) {
                    set({ session: data.session });
                    return true;
                }
            } catch (error: Error | any) {
                if (intervalIdSigned) {
                    clearInterval(intervalIdSigned);
                    const setError = useStoreError.getState().setError;
                    setError(error.response?.data?.message || error.response?.data || error?.message);
                    // updateSession({ status: STATUS.ID_LOAD_SUCCESS });
                }
            }
        };

        // let attemptCount = 0;
        // const maxAttempts = 150 // 150 * 2 = 300 seconds;

        timeoutSigned = setTimeout(() => {
            intervalIdSigned = setInterval(async () => {
                // attemptCount++;
                const signed = await checkSignedPolling();
                if (signed && intervalIdSigned) {
                    clearInterval(intervalIdSigned);
                    ReactGA.event({
                        category: 'Contract',
                        action: `Zbt: #5 Contract signed ${flowSorgente == "calcolaFree" && "CalcolaFreeFlow"}`,
                        label: "Successfully"
                    });
                    updateSession({ status: STATUS.SUCCESS });
                }

                // if (attemptCount >= maxAttempts && intervalIdSigned) {
                //     clearInterval(intervalIdSigned);
                //     const setError = useStoreError.getState().setError;
                //     setError("Errore durante la conferma della firma");
                //     updateSession({ status: STATUS.ID_LOAD_SUCCESS });
                // }
            }, 2000);
        }, isTimeout ? 15000 : 100);

        return () => {
            if (intervalIdSigned) {
                clearInterval(intervalIdSigned);
            }
            clearTimeout(timeoutSigned);
        };
    },
    openSession: async (idSession: number) => {
        const token = localStorage.getItem('throughAdminToken') || localStorage.getItem('token');

        if (token && idSession) {
            useStoreLoader.getState().setLoaderBg(true);
            useStoreLoader.getState().startLoader();
            localStorage.setItem('isOnboarding', 'true');
            return `${ROUTES.ONBOARDING}?token=${token}&idSession=${idSession}`;
        }
        return null;
    },
    createSession: async (idUser: number) => {
        try {
            const { data } = await api.post("/sessions/create", { idUser });
            const token = localStorage.getItem('throughAdminToken') || localStorage.getItem('token');

            if (token && data.session?.id) {
                localStorage.setItem('isOnboarding', 'true');
                return `${ROUTES.ONBOARDING}?token=${token}&idSession=${data.session?.id}`;
            }
            return null

        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        };
    },
    openFriendSession: async (idSession: number, idUser: number) => {
        try {
            const { data } = await api.post('/friend/getToken', { idFriend: idUser });

            if (data.friendToken && idSession) {
                useStoreLoader.getState().setLoaderBg(true);
                useStoreLoader.getState().startLoader();
                localStorage.setItem('isOnboarding', 'true');
                return `${ROUTES.ONBOARDING}?token=${data.friendToken}&idSession=${idSession}`;
            }
            return null;

        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    createFriendSession: async (idUser: number) => {
        try {
            const { data } = await api.post("/sessions/create", { idUser });
            const { data: token } = await api.post(`/friend/getToken`, { idFriend: idUser });
            localStorage.setItem('isOnboarding', 'true');

            if (data && token && idUser) {
                return `${ROUTES.ONBOARDING}?token=${token.friendToken}&idSession=${data.session?.id}`;
            }
            return null;

        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }

    },
    archiveSession: async (sessionId, callback) => {
        await api.delete(`/archiveSessionByUser/${sessionId}`);
        callback();
    },
    getMongoSessionInfo: async (sessionId) => {
        try {
            const { data } = await api.get(`/sessions/getMongoInfo/${sessionId}`);
            set({ sessionInfoMongo: data.data });
        } catch (error: Error | any) {
            const setError = useStoreError.getState().setError;
            setError(error.response?.data?.message || error.response?.data || error?.message);
        }
    },
    sessionInfoMongo: null,
}));
