const STATUS = {
    ONBOARDING: 1,
    BILL_LOAD_SINGLE: 2,
    BILL_LOAD_MULTIPLE: 3,
    OTP: 4,
    OTP_RESULT: 5,
    ID_LOAD: 6,
    ID_LOAD_SUCCESS: 7,
    SIGNATURE: 8,
    SUCCESS: 9
};

export default STATUS;