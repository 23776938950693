import { useNavigate } from "react-router-dom";
import ROUTES from "@routes/constants";
import STATUS from "@constants/STATUS";

function useStatusRedirect() {
    const navigate = useNavigate();

    const statusRedirect = (status: number) => {
        switch (status) {
            case STATUS.ONBOARDING:
                navigate(`${ROUTES.ONBOARDING}`);
                break;
            case STATUS.BILL_LOAD_SINGLE:
                navigate(`${ROUTES.BILL_LOAD}?is_single=true`)
                break;
            case STATUS.BILL_LOAD_MULTIPLE:
                navigate(`${ROUTES.BILL_LOAD}?is_single=false`)
                break;
            case STATUS.OTP:
                navigate(ROUTES.OTP);
                break;
            case STATUS.OTP_RESULT:
                navigate(ROUTES.OTP_RESULT);
                break;
            case STATUS.ID_LOAD:
                navigate(ROUTES.ID_LOAD);
                break;
            case STATUS.ID_LOAD_SUCCESS:
                navigate(ROUTES.ID_LOAD_SUCCESS)
                break;
            case STATUS.SIGNATURE:
                navigate(ROUTES.SIGNATURE);
                break;
            case STATUS.SUCCESS:
                navigate(ROUTES.SUCCESS);
                break;
            default:
                break;
        }
    };

    return statusRedirect;
}

export default useStatusRedirect;

