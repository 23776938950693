const STATUS_MAPPING = {
    1: "/onboarding",
    2: "/bill_load",
    3: "/bill_load",
    4: "/otp",
    5: "/otp_result",
    6: "/id_load",
    7: "/id_load_success",
    8: "/signature",
    9: "/success",
};

export default STATUS_MAPPING;