import styles from './index.module.scss';
import clsx from 'clsx';
// import LogoSliceYellow from '@assets/images/logo-slice-yellow.svg';
// import LogoSliceAzure from '@assets/images/logo-slice-azure.svg';
// import LogoSliceBlue from '@assets/images/logo-slice-blue.svg';

type LoaderProps = {
    isAbsolute?: boolean;
    isBg?: boolean;
    height?: string;
};

const Loader: React.FC<LoaderProps> = ({ isAbsolute, isBg, height }) => {
    return (
        <div className={clsx(styles.loader, { [styles.isBg]: isBg })} style={isAbsolute ? { position: "absolute", height } : { height }}>
            <div className={styles.loader__spinner} />
            {/* <div className={styles.loader__logo}>
                <div className={styles.loader__logo__container}>
                    <LogoSliceYellow />
                    <LogoSliceAzure />
                    <LogoSliceBlue />
                </div>
            </div> */}
        </div>
    )
};

export default Loader;