import { useEffect } from 'react';

const useHotjar = (hotjarId: string, hotjarVersion: string) => {
    useEffect(() => {
        (function (h: any, o, t, j) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: hotjarId, hjsv: hotjarVersion };
            const a: HTMLHeadElement | undefined = o.getElementsByTagName('head')[0];
            const r: HTMLScriptElement | undefined = o.createElement('script'); r.async = true;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }, [hotjarId, hotjarVersion]);
};

export default useHotjar;