import { create } from "zustand";

type StoreLoader = {
    loader: number;
    isBg: boolean;
    setLoaderBg: (isBg: boolean) => void;
    startLoader: () => void;
    stopLoader: () => void;
};

export const useStoreLoader = create<StoreLoader>((set) => ({
    loader: 0,
    isBg: false,
    setLoaderBg: (isBg: boolean) => set({ isBg }),
    startLoader: () => set((state) => ({ loader: state.loader + 1 })),
    stopLoader: () => set((state) => ({ loader: state.loader > 0 ? state.loader - 1 : 0 })),
}));