export const CONTRACT_STATUS = [
/* 0 */{title: 'Carica bolletta', description: 'La tua sessione è stata creata, carica una bolletta da esaminare!', action: 'Continua'}, 
/* 1 */{title: 'Carica bolletta', description: 'La tua sessione è stata creata, carica una bolletta da esaminare!', action: 'Continua'},
/* 2 */{title: 'Carica bolletta', description: 'La tua sessione è stata creata, carica una bolletta da esaminare!', action: 'Continua'},
/* 3 */{title: 'Carica bolletta', description: 'La tua sessione è stata creata, carica una bolletta da esaminare!', action: 'Continua'},
/* 4 */{title: 'Carica Documenti', description: "La tua bolletta è stata caricata correttamente, clicca per andare al prossimo step e caricare un documento d'identità!", action: 'Continua'},
/* 5 */{title: 'Carica Documenti', description: "La tua bolletta è stata caricata correttamente, clicca per andare al prossimo step e caricare un documento d'identità!", action: 'Continua'},
/* 6 */{title: 'Carica Documenti', description: "La tua bolletta è stata caricata correttamente, clicca per andare al prossimo step e caricare un documento d'identità!", action: 'Continua'},
/* 7 */{title: 'Firma Contratto', description: 'Manca poco! Il tuo documento è stato caricato correttamente, Firma adesso il contratto e risparmia con noi!', action: 'Completa'},
/* 8 */{title: 'Firma Contratto', description: 'Manca poco! Il tuo documento è stato caricato correttamente, Firma adesso il contratto e risparmia con noi!', action: 'Completa'},
/* 9 */{title: 'Metodo di pagamento', description: 'Manca poco! Aggiungi un metodo di pagamento!', action: 'Completa'},
/*10 */{title: 'Metodo di pagamento', description: 'Manca poco! Aggiungi un metodo di pagamento!', action: 'Completa'},
/*11 */{title: 'In Elaborazione', description: 'Fantastico! Abbiamo ricevuto il contratto firmato, il nostro team sta verificando i documenti caricati.', action: 'Visualizza'}, 
/*12 */{title: 'In Attesa di Attivazione', description: 'Benvenuto in Mr. Bolletta! Il tuo contratto entrerà in vigore dal 01 del prossimo mese!.', action: 'Visualizza'},
];

export const CONTRACT_TYPE = [{value:'G', label:'Gas'}, {value:'E', label:'Elettricità'}];

export const USER_COLOR = ['#7C00FE', '#FF6C85', '#FFB422', '#1DD7BD', '#7932d3', '#e036a8', '#363abf', '#36bf48', '#F5004F', '#FF5B00', '#FF6969', '#00C1D4'];

